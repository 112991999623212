import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes formations';
export const txtformation = 'formation';
export const txtformationpluriel = 'formations';
export const titleAlveole = 'Mes formations';
export const telContactFormation = "02 51 91 55 98"


export const alveoleLogoSvg = "./../images/icon-alv-docs-veto.svg";
/************************ DeV ******************************************************/
export const zUrlBack = "https://api-mesformations-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const zUrlMailler = "https://api-serviceaccounts-dev.cristal-hub.fr";
export const zUrlAPIProfile = "https://api-profile-dev.cristal-hub.fr";
/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000";
// export const zUrlBackNotifications = "https://localhost:8001";
// export const zUrlMailler = "https://localhost:8000";
// export const zUrlProfile = "https://localhost:8001";

export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F' : '#ffffff ',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '48px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    },
    marginBottom: '14px'
}));

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLProfile = "https://profile-dev.cristal-hub.fr";
